@import 'variables';
$red-dark: rgba(120, 11, 11, 1);
$red-light: rgba(244, 3, 3, 1);

.button-primary {
  background-color: $primaryColor !important;
  color: $appWhite !important;
  border-width: 0;

}

.button-seconday {
  background-color: $secondaryColor !important;
  color: $appWhite !important;
  border-width: 0;
}

.fillHeight {
  min-height: 100vh;
}

.gradientBackGround {
  background-image: linear-gradient(to right, $primaryColor , $appWhite);
}

.input-error-message {
  color: crimson;
  font-size: medium;
}

.login-page {
  min-height: 100vh;
  background-image: url('../resources/jpg/loginbg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;

  .login-section {
    background-color: #fffa;
  }

  .text-fields {
    background-color: #fff;
  }
}

.nav-bar {
  background-color: $primaryColor;
  color: white;
}

.side-menu {
  background-color: $primaryColor;
  color: white;
}


.search-page {
  .header {
    background: rgb(244, 3, 3);
    background: radial-gradient(circle, rgba(244, 3, 3, 1) 0%, rgba(120, 11, 11, 1) 100%);

    input,
    select {
      border-radius: 0;
      background: #fff;
      border: 0;
    }

  }
}

.login-page-v2 {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background: rgb(244, 3, 3);
  background: radial-gradient(circle, $red-light 0%, $red-dark 100%);

  .login-section {
    background-color: #fff;
    color: #111;

    Button {
      color: #fff;
      background-color: $red-dark;
    }

    .error-meesage {
      color: red;
    }
  }

  .logo-section {
    cursor: pointer;

    .slogan-text {
      color: $appWhite;
    }
  }
}

.register-page {
  .header-bar {
    border-bottom: 1px $red-dark solid;
  }

  .register-section {
    p {
      margin-bottom: .25em;
    }

    input,
    textarea,
    select {
      margin-bottom: .5em;
    }

    .personal-infomation {}
  }

}